// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-contact-success-jsx": () => import("./../../../src/pages/contact/success.jsx" /* webpackChunkName: "component---src-pages-contact-success-jsx" */),
  "component---src-pages-detail-bm-jsx": () => import("./../../../src/pages/detail-bm.jsx" /* webpackChunkName: "component---src-pages-detail-bm-jsx" */),
  "component---src-pages-detail-data-jsx": () => import("./../../../src/pages/detail-data.jsx" /* webpackChunkName: "component---src-pages-detail-data-jsx" */),
  "component---src-pages-detail-elog-jsx": () => import("./../../../src/pages/detail-elog.jsx" /* webpackChunkName: "component---src-pages-detail-elog-jsx" */),
  "component---src-pages-detail-fs-jsx": () => import("./../../../src/pages/detail-fs.jsx" /* webpackChunkName: "component---src-pages-detail-fs-jsx" */),
  "component---src-pages-detail-hopper-jsx": () => import("./../../../src/pages/detail-hopper.jsx" /* webpackChunkName: "component---src-pages-detail-hopper-jsx" */),
  "component---src-pages-detail-izyfit-jsx": () => import("./../../../src/pages/detail-izyfit.jsx" /* webpackChunkName: "component---src-pages-detail-izyfit-jsx" */),
  "component---src-pages-detail-jb-jsx": () => import("./../../../src/pages/detail-jb.jsx" /* webpackChunkName: "component---src-pages-detail-jb-jsx" */),
  "component---src-pages-detail-ls-jsx": () => import("./../../../src/pages/detail-ls.jsx" /* webpackChunkName: "component---src-pages-detail-ls-jsx" */),
  "component---src-pages-detail-mb-jsx": () => import("./../../../src/pages/detail-mb.jsx" /* webpackChunkName: "component---src-pages-detail-mb-jsx" */),
  "component---src-pages-detail-tc-jsx": () => import("./../../../src/pages/detail-tc.jsx" /* webpackChunkName: "component---src-pages-detail-tc-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-work-logo-jsx": () => import("./../../../src/pages/work-logo.jsx" /* webpackChunkName: "component---src-pages-work-logo-jsx" */),
  "component---src-pages-work-print-jsx": () => import("./../../../src/pages/work-print.jsx" /* webpackChunkName: "component---src-pages-work-print-jsx" */),
  "component---src-pages-work-product-design-jsx": () => import("./../../../src/pages/work-product-design.jsx" /* webpackChunkName: "component---src-pages-work-product-design-jsx" */),
  "component---src-pages-work-uxui-design-jsx": () => import("./../../../src/pages/work-uxui-design.jsx" /* webpackChunkName: "component---src-pages-work-uxui-design-jsx" */)
}

